import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

import {
  Section,
  SectionHeading,
  SectionLede,
} from "../../styledComponents/section"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const Thankyou = ({ data }) => {

  return (
    <>
    <Layout>
      <Section>
        <Heading level={1}>
          Thank you
        </Heading>
        <SectionLede>
          <p>We’ve got your details and one of our team will be in touch very soon.</p>
        </SectionLede>       
      </Section>
    </Layout>
    </>
  )
}

export default Thankyou
